import React, { useState, useEffect } from 'react';
import './Cart.css';
import MobNavbar from '../components/MobNav';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoginModal from '../components/LoginModal'; 

const Cart = () => {
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [userData, setUserData] = useState(null); // Данные пользователя

   const handleLogin = () => setIsLoginModalOpen(true);
  const handleCloseModal = () => setIsLoginModalOpen(false);

  const handleUserLogin = (userData, token) => {
    setIsLoggedIn(true);
    setUserData(userData);
    localStorage.setItem('token', token);
    setIsLoginModalOpen(false);
  };


  // Функция для загрузки корзины из localStorage
  const loadCartFromLocalStorage = () => {
    try {
      const savedCart = localStorage.getItem('cart');
      if (savedCart) {
        const parsedCart = JSON.parse(savedCart);
        if (Array.isArray(parsedCart)) {
          setCart(parsedCart);
        } else {
          console.error('Cart data in localStorage is not an array');
          setCart([]);
        }
      }
    } catch (error) {
      console.error('Error loading cart from localStorage:', error);
      setCart([]); // Сбрасываем корзину при ошибке
    }
  };


  useEffect(() => {
    loadCartFromLocalStorage(); // Загружаем корзину при загрузке компонента
    fetchUserData();

    // Добавляем слушателя события `storage`, чтобы отслеживать изменения в localStorage
    const handleStorageChange = (event) => {
      if (event.key === 'cart') {
        loadCartFromLocalStorage();

      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Убираем слушателя при размонтировании компонента
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  const handleRemoveItem = (productId) => {
    const updatedCart = cart.filter((item) => item && item._id !== productId);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    // Отправляем кастомное событие для обновления других компонентов
    const event = new Event('cartUpdate');
    window.dispatchEvent(event);
  };
  

  const handleIncreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleDecreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => {
      if (item && item.price) {
        return total + item.price * item.quantity;
      }
      return total;
    }, 0);
  };
  // Проверка авторизации при монтировании компонента
  useEffect(() => {
    if (!isLoggedIn) {
      setIsLoginModalOpen(true); // Открыть модальное окно, если пользователь не авторизован
    } else {
      fetchUserData();
    }
  }, [isLoggedIn]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://www.kerambit.me/api/user/profile', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Ошибка загрузки данных пользователя:', error);
      setIsLoggedIn(false); // Если ошибка, считаем пользователя неавторизованным
    }
  };

  const getRarityClassName = (rarity) => {
    switch (rarity) {
      case 'Consumer Grade':
        return 'rarity-consumer-grade';
      case 'Industrial Grade':
        return 'rarity-industrial-grade';
      case 'Mil-Spec Grade':
        return 'rarity-mil-spec-grade';
      case 'Restricted':
        return 'rarity-restricted';
      case 'Classified':
        return 'rarity-classified';
      case 'Covert':
        return 'rarity-covert';
      case 'Knife':
        return 'rarity-Knives';
      default:
        return '';
    }
  };
  const handleCheckout = async () => {
  if (!isLoggedIn) {
    setIsLoginModalOpen(true);
    return;
  }

  try {
    const userId = userData._id || userData.id;

    if (!userId) {
      console.error('User ID is missing in userData:', userData);
      alert('User data is missing. Please try logging in again.');
      return;
    }

    const response = await axios.post('https://www.kerambit.me/api/checkout/test', {
      amount: calculateTotal(),
      user: {
        _id: userId,
        email: userData.email,
      },
      items: cart, // Передаем корзину с товарами
    });

    if (response.data.success) {
      // Убедитесь, что данные URL существуют
      if (response.data.url) {
        localStorage.removeItem('cart'); // Очищаем корзину
        setCart([]);
        window.location.href = response.data.url; // Перенаправление на страницу оплаты
      } else {
        console.error('Payment URL missing in response:', response.data);
        alert('Error during checkout. Please try again.');
      }
    } else {
      console.error('Ошибка оплаты:', response.data.error);
      alert('Ошибка при обработке оплаты.');
    }
  } catch (error) {
    console.error('Ошибка:', error);
    alert('Ошибка при обработке оплаты.');
  }
};

  return (
    <div className={`shop-container `}>
      <MobNavbar />
      <div className="shop-content">
        <h1>Your Cart</h1>

        {cart.length > 0 ? (
          <div className="shop-items">
            {cart.map((item, index) => {
              if (item === null) {
                return null;
              }

              return (
                <div className={`shop-item ${getRarityClassName(item.Rarity)}`} key={item._id || index}>
                  <div className="item-image-container">
                    <div className="item-image-inside">
                      <img
                        src={
                          item.imageUrl.startsWith('/images/') 
                            ? item.imageUrl  // Если путь начинается с /images/, используем его как есть
                            : require(`../assets/${item.imageUrl}`) // Для старых данных используем require
                        }
                        alt={item.name}
                        className="item-image"
                      />
                    </div>
                  </div>
                  <div className="item-details">
                    <p>{item.category} • {item.name}</p>
                    <h3>{item.description}</h3>
                    <p className="item-price">{item.price}€</p>
                    <span className="item-quality-tag">{item.wear}</span>
                  </div>
                  <div className="remove-quantity-controls">
                    <div className="quantity-controls">
                      <button onClick={() => handleDecreaseQuantity(item._id)}>-</button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleIncreaseQuantity(item._id)}>+</button>
                    </div>
                    <button className="remove-item-button" onClick={() => handleRemoveItem(item._id)}>
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="empty-cart">Your cart is empty.</p>
        )}

        <div className="order-summary">
          <h2>Order Summary</h2>
          <p className="order-total">
            Total: <span>€{calculateTotal().toFixed(2)}</span>
          </p>
          {cart.length > 0 && (
            <button
              className="checkout-button"
              onClick={handleCheckout}
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Continue to Checkout"}
            </button>
          )}
        </div>
      </div>
       <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleCloseModal}
        onUserLogin={handleUserLogin}
      />
    </div>
  );
};

export default Cart;
