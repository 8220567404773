import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Game.css'; // Подключаем стили

import arrowIcon from '../assets/srelka.svg'; // Подключаем иконку стрелки
import loadingGif from '../assets/loading.gif';
import FiltersMobile from '../components/FiltersMobile'; 
import { useNavigate } from 'react-router-dom';
import MobNavbar from '../components/MobNav';

const Game = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false); 
  const navigate = useNavigate(); // Используем для навигации
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    priceMin: 0,
    priceMax: 19999,
    wearLevels: [],
    types: [],
    rarity: [],
  });
  const [sortOption, setSortOption] = useState('');
  const [openFilterSections, setOpenFilterSections] = useState({
    price: false,
    wearLevels: false,
    types: false,
    rarity: false,
  });

  // Открыть мобильные фильтры
  const handleOpenMobileFilters = () => {
    setIsMobileFiltersOpen(true);
  };

  // Закрыть мобильные фильтры
  const handleCloseMobileFilters = () => {
    setIsMobileFiltersOpen(false);
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://www.kerambit.me/api/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false); // Устанавливаем состояние загрузки в false после завершения
      }
    };

    fetchProducts();

    // Загрузка корзины из локального хранилища
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const handleAddToCart = async (product) => {
    try {
      const token = localStorage.getItem('token');
      let updatedCart = [...cart];
  
      // Проверка, есть ли продукт уже в корзине
      const existingProductIndex = updatedCart.findIndex((item) => item._id === product._id);
  
      if (existingProductIndex !== -1) {
        // Если продукт уже в корзине, увеличиваем его количество
        updatedCart[existingProductIndex].quantity += 1;
      } else {
        // Если продукта нет, добавляем его с количеством 1
        updatedCart.push({ ...product, quantity: 1 });
      }
  
      if (token) {
        // Если пользователь авторизован, добавляем товар в корзину через API
        await axios.post(
          'https://www.kerambit.me/api/cart/add',
          { productId: product._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
  
      // Локальное обновление состояния корзины
      setCart(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Сохранение в локальном хранилище
  
      // Отправляем кастомное событие для обновления других компонентов
      const event = new Event('cartUpdate');
      window.dispatchEvent(event);
  
      console.log('Product added to cart successfully');
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };
  

  const handleIncreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    // Отправляем кастомное событие для обновления других компонентов
    const event = new Event('cartUpdate');
    window.dispatchEvent(event);
  };
  
  const handleDecreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    // Отправляем кастомное событие для обновления других компонентов
    const event = new Event('cartUpdate');
    window.dispatchEvent(event);
  };
  
  const handleFilterChange = (filterType, value) => {
    const updatedValues = filters[filterType].includes(value)
      ? filters[filterType].filter((v) => v !== value)
      : [...filters[filterType], value];

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: updatedValues,
    }));
  };

  const handleToggleFilterSection = (section) => {
    setOpenFilterSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const filteredProducts = products.filter((product) => {
    return (
      product.price >= filters.priceMin &&
      product.price <= filters.priceMax &&
      (filters.wearLevels.length === 0 || filters.wearLevels.includes(product.wear)) &&
      (filters.types.length === 0 || filters.types.includes(product.category)) &&
      (filters.rarity.length === 0 || filters.rarity.includes(product.Rarity))
    );
  });

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    switch (sortOption) {
      case 'price-asc':
        return a.price - b.price;
      case 'price-desc':
        return b.price - a.price;
      default:
        return 0;
    }
  });

  const handlePriceChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };
  const handleCardClick = (productId) => {
    navigate(`/product/${productId}`);
  };
  
  
  const handleClearFilters = () => {
    setFilters({
      priceMin: 0,
      priceMax: 19999,
      wearLevels: [],
      types: [],
      rarity: [],
    });
  };

  const [isSortOpen, setIsSortOpen] = useState(false);

  return (
    <div className={`game-container `}>
      <MobNavbar/>
      {/* Кнопка фильтров для мобильных устройств */}


     {/* Панель мобильных фильтров */}
      {isMobileFiltersOpen && (
        <FiltersMobile
          onClose={handleCloseMobileFilters}
          filters={filters}
          openFilterSections={openFilterSections}
          handleFilterChange={handleFilterChange}
          handleToggleFilterSection={handleToggleFilterSection}
          handlePriceChange={handlePriceChange}
          handleClearFilters={handleClearFilters}
        />
      )}
      <aside className="filters">
        
        <h1>Filters</h1>

        {/* Price filter */}
        <div className="filter-section">
          <div className="filter-header" onClick={() => handleToggleFilterSection('price')}>
            <span>Price</span>
            <img
              src={arrowIcon}
              alt="Toggle"
              className={`filter-arrow ${openFilterSections.price ? 'open' : ''}`}
            />
          </div>
          {openFilterSections.price && (
            <div className="filter-content">
              <div className="price-filter">
                <input
                  type="number"
                  value={filters.priceMin}
                  onChange={(e) => handlePriceChange('priceMin', Number(e.target.value))}
                  placeholder="€ 0"
                  step="any"
                  inputMode="numeric"
                  className="price-input" // Добавляем класс для стиля
                />
                <span>—</span>
                <input
                  type="number"
                  value={filters.priceMax}
                  onChange={(e) => handlePriceChange('priceMax', Number(e.target.value))}
                  placeholder="€ 19999"
                  step="any"
                  inputMode="numeric"
                  className="price-input" // Добавляем класс для стиля
                />
              </div>
            </div>
          )}
        </div>

        {/* Wear Levels filter */}
        <div className="filter-section">
          <div className="filter-header" onClick={() => handleToggleFilterSection('wearLevels')}>
            <span>Skin Wear levels</span>
            <img
              src={arrowIcon}
              alt="Toggle"
              className={`filter-arrow ${openFilterSections.wearLevels ? 'open' : ''}`}
            />
          </div>
          {openFilterSections.wearLevels && (
            <div className="filter-checkboxes">
              <label>
                <input
                  type="checkbox"
                  checked={filters.wearLevels.includes('Factory new')}
                  onChange={() => handleFilterChange('wearLevels', 'Factory new')}
                />
                Factory new
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.wearLevels.includes('Field-tested')}
                  onChange={() => handleFilterChange('wearLevels', 'Field-tested')}
                />
                Field-tested
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.wearLevels.includes('Well-worn')}
                  onChange={() => handleFilterChange('wearLevels', 'Well-worn')}
                />
                Well-worn
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.wearLevels.includes('Minimal wear')}
                  onChange={() => handleFilterChange('wearLevels', 'Minimal wear')}
                />
                Minimal wear
              </label>
            </div>
          )}
        </div>

        {/* Type filter */}
        <div className="filter-section">
          <div className="filter-header" onClick={() => handleToggleFilterSection('types')}>
            <span>Type</span>
            <img
              src={arrowIcon}
              alt="Toggle"
              className={`filter-arrow ${openFilterSections.types ? 'open' : ''}`}
            />
          </div>
          {openFilterSections.types && (
            <div className="filter-checkboxes">
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('Knives')}
                  onChange={() => handleFilterChange('types', 'Knives')}
                />
                Knives
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('Rifles')}
                  onChange={() => handleFilterChange('types', 'Rifles')}
                />
                Rifles
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('Gloves')}
                  onChange={() => handleFilterChange('types', 'Gloves')}
                />
                Gloves
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('Pistols')}
                  onChange={() => handleFilterChange('types', 'Pistols')}
                />
                Pistols
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('SMGs')}
                  onChange={() => handleFilterChange('types', 'SMGs')}
                />
                SMGs
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('Shotguns')}
                  onChange={() => handleFilterChange('types', 'Shotguns')}
                />
                Shotguns
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('Machine guns')}
                  onChange={() => handleFilterChange('types', 'Machine guns')}
                />
                Machine guns
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.types.includes('Key')}
                  onChange={() => handleFilterChange('types', 'Key')}
                />
                Key
              </label>
            </div>
          )}
        </div>

        {/* Rarity filter */}
        <div className="filter-section">
          <div className="filter-header" onClick={() => handleToggleFilterSection('rarity')}>
            <span>Rarity</span>
            <img
              src={arrowIcon}
              alt="Toggle"
              className={`filter-arrow ${openFilterSections.rarity ? 'open' : ''}`}
            />
          </div>
          {openFilterSections.rarity && (
            <div className="filter-checkboxes">
              <label>
                <input
                  type="checkbox"
                  checked={filters.rarity.includes('Consumer Grade')}
                  onChange={() => handleFilterChange('rarity', 'Consumer Grade')}
                />
                Consumer Grade
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.rarity.includes('Industrial Grade')}
                  onChange={() => handleFilterChange('rarity', 'Industrial Grade')}
                />
                Industrial Grade
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.rarity.includes('Mil-Spec Grade')}
                  onChange={() => handleFilterChange('rarity', 'Mil-Spec Grade')}
                />
                Mil-Spec Grade
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.rarity.includes('Restricted')}
                  onChange={() => handleFilterChange('rarity', 'Restricted')}
                />
                Restricted
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.rarity.includes('Classified')}
                  onChange={() => handleFilterChange('rarity', 'Classified')}
                />
                Classified
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.rarity.includes('Covert')}
                  onChange={() => handleFilterChange('rarity', 'Covert')}
                />
                Covert
              </label>
            </div>
            
          )}
        </div>
        <div className="filter-clear">
          <span onClick={handleClearFilters}>Clear filters</span>
        </div>
      </aside>

      <section className="products-section">
        <h1 className="product-game-title">Catalog</h1>
        
        {/* Контейнер для кнопки фильтров и сортировки */}
        <div className="filters-and-sort">
          <button className="mobile-filters-button" onClick={handleOpenMobileFilters}>
            Filters
          </button>

          <div className="sort-options">
            <div className="sort-select-wrapper">
              <select
                id="sort"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                onClick={() => setIsSortOpen(!isSortOpen)}
              >
                <option value="">Sort By</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
              </select>
              <span className={`filter-arrow-sort ${isSortOpen ? 'open' : ''}`} />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="loading-container">
            <img src={loadingGif} alt="Loading..." className="loading-gif" />
          </div>
        ) : (
          <div className="products-game-grid">
            {sortedProducts.map((product) => {
              const cartItem = cart.find((item) => item._id === product._id);
              return (
                <div
                  className="product-cart"
                  key={product._id}
                  data-rarity={product.Rarity}
                >
                  <div className="product-cart_inside">
                    <span className="product-game-quality-tag">{product.wear}</span>
                    <div className="product-image-container" onClick={() => ( handleCardClick(product._id) )}>
                      <img
                        src={
                          product.imageUrl.startsWith('/images/') 
                            ? product.imageUrl  // Если путь начинается с /images/, используем его как есть
                            : require(`../assets/${product.imageUrl}`) // Для старых данных используем require
                        }
                        alt={product.name}
                        className="product-game-image"
                      />
                      
                    </div>
                    <div className="product-game-info">
                      <div className="product-game-name-category">
                        <span className="product-game-category">
                          {product.category} • {product.name}
                        </span>
                        <h4 className="product-name">{product.description}</h4>
                      </div>
                      <div className="product-game-price-quality">
                        <p className="product-game-price">{product.price} €</p>
                      </div>
                    </div>
                    <div className="product-game-footer">
                      {cartItem ? (
                        <div className="quantity-controls">
                          <button onClick={() => handleDecreaseQuantity(product._id)}>-</button>
                          <span>{cartItem.quantity}</span>
                          <button onClick={() => handleIncreaseQuantity(product._id)}>+</button>
                        </div>
                      ) : (
                        <button className="buy-game-now-button" onClick={() => handleAddToCart(product)}>
                          <img src={require('../assets/shop.png')} alt="Cart" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
};

export default Game;
